import { useNavigate, Link as RouterLink } from "react-router-dom";
import { Typography, Grid, FormControl, MenuItem } from "@mui/material";

const Yearslider = ({pageYear, filters}) => {

    const navigate = useNavigate()

    // STYLING YEARSLIDER
    
    const yearsliderSx = {
        width: {xs: "100%", md: 960 },
        justifyContent: "center",
        textAlign: "center",
        // height: {xs: "", sm: 30, md: 37},
        m: "10px auto"
    }

    const yearsConSx = {
        display: "ruby", 
        // maxWidth: '200px',
        justifyContent: "center", 
        height: "100%", 
        bgcolor: "rgba(237, 230, 219, 0.06)",
        // bgcolor: "rgba(255,255,255,0.3)"
    }

    const yearItemSx = {
        m: "0 1px",
        minHeight: "100%",
        fontSize: { xs: "2.5vw", sm: 14, md: 15 },
        bgcolor: "rgba(255,255,255,0.12)",
        color: "info.main",
        // bgcolor: "rgba(255,255,255,0.75)",
        ":hover": {
            color: "primary.main", 
            backgroundColor: "info.dark"},
        "&.Mui-selected": {
            color: "primary.main", 
            backgroundColor: "info.dark",
            ":hover": {
                backgroundColor: "info.main",
                color: "info.dark"
            }
        }
    }

    const yearItemClickedSx = {
        m: "0 1px",
        minHeight: "100%",
        fontSize: { xs: "2.5vw", sm: 14, md: 15 },
        color: "primary.main", 
        backgroundColor: "info.dark",
        ":hover": {
            backgroundColor: "info.main",
            color: "info.dark"
        }
    }
    
    // 

    const years = [
        '2018',
        '2019',
        '2020',
        '2021',
        '2022',
        '2023',
        '2024',
        'ALL'
    ]

    // LAYOUT
    const getPage = (e) => {
        // console.log(e.target.getAttribute('value'))
        let nav 
        filters === '' ? nav = '' : nav = '?' + filters
        e.preventDefault()
        navigate("/years/" + e.target.getAttribute('value') + nav)
    }

    return (
        <Typography component="span" variant="body2"> 
        <Grid container sx={{...yearsliderSx}}>

               <FormControl sx={{width: "100%", height: "100%", justifyContent: "flex-end"}}>
                    <Grid sx={{...yearsConSx}}>

                    {years.map((year) => {

                                {/* console.log(year) */}

                                let yearStyle
                                year === pageYear ? yearStyle = yearItemClickedSx : yearStyle = yearItemSx

                                return (
                                    <RouterLink className='year' key={year} to={"/years/" + year} onClick={(e) => getPage(e)}>
                                        <MenuItem 
                                        value={year}
                                        sx={yearStyle}
                                        >{year}
                                        </MenuItem>
                                    </RouterLink>
                                )
                            })}

                    </Grid>
                </FormControl>

        </Grid>
        </Typography>

    );
}

export default Yearslider;
